<template>
  <div class="step1">
    <div class="form-name-parent-box">
      <!-- 氏名 -->
      <p class="form-label">
        <span class="attention">※</span>氏名（漢字）
      </p>
      <div class="form-name-child-box">
        <input
          id="first_name"
          v-model="formRef.first_name.value"
          class="uk-input name-input"
          name="first_name"
          type="text"
          placeholder="姓"
        >
        <input
          id="last_name"
          v-model="formRef.last_name.value"
          class="uk-input"
          name="last_name"
          type="text"
          placeholder="名"
        >
      </div>
      <error
        v-if="formRef.first_name.errorMessage"
        :message="formRef.first_name.errorMessage"
      />
      <error
        v-if="formRef.last_name.errorMessage"
        :message="formRef.last_name.errorMessage"
      />
    </div>

    <div class="form-name-parent-box">
      <!-- セイ -->
      <p class="form-label">
        <span class="attention">※</span>氏名（カナ）
      </p>
      <div class="form-name-child-box">
        <input
          id="first_kana"
          v-model="formRef.first_kana.value"
          class="uk-input name-input"
          name="first_kana"
          type="text"
          placeholder="セイ"
        >
        <input
          id="last_kana"
          v-model="formRef.last_kana.value"
          class="uk-input"
          name="last_kana"
          type="text"
          placeholder="メイ"
        >
      </div>
      <error
        v-if="formRef.first_kana.errorMessage"
        :message="formRef.first_kana.errorMessage"
      />
      <error
        v-if="formRef.last_kana.errorMessage"
        :message="formRef.last_kana.errorMessage"
      />
    </div>

    <!-- メール -->
    <div class="wrap-single-form">
      <div>
        <p class="form-label">
          <span class="attention">※</span>登録アドレス
        </p>
      </div>
      <div class="form-input">
        <input
          id="mail"
          v-model="formRef.mail.value"
          class="uk-input"
          type="email"
          name="mail"
          placeholder="例 : sponavi-agent@xxx.com"
        >
        <error
          v-if="formRef.mail.errorMessage"
          :message="formRef.mail.errorMessage"
        />
      </div>
    </div>

    <!-- 電話番号 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>電話番号
      </p>
      <div class="form-input">
        <input
          id="tel"
          v-model="formRef.tel.value"
          class="uk-input"
          type="tel"
          name="tel"
          maxlength="11"
          placeholder="09001234567"
        >
        <error
          v-if="formRef.tel.errorMessage"
          :message="formRef.tel.errorMessage"
        />
      </div>
    </div>

    <!-- 郵便番号 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>郵便番号
      </p>
      <div class="form-input">
        <input
          id="postcode"
          v-model="formRef.postcode.value"
          class="uk-input"
          type="text"
          name="postcode"
          maxlength="7"
          placeholder="0000000"
        >
        <error
          v-if="formRef.postcode.errorMessage"
          :message="formRef.postcode.errorMessage"
        />
      </div>
    </div>

    <!-- 卒業予定年 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>卒業予定年
      </p>
      <div class="form-input">
        <select
          id="gradyear"
          v-model="formRef.gradyear.value"
          class="uk-select"
          name="gradyear"
        >
          <option value="">
            選択してください
          </option>
          <option
            v-for="g in gradyearRef"
            :key="g"
            :value="g"
          >
            {{ dispGradyear(g) }}
          </option>
        </select>
        <error
          v-if="formRef.gradyear.errorMessage"
          :message="formRef.gradyear.errorMessage"
        />
      </div>
    </div>

    <!-- 学校名 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>学校名
      </p>
      <div class="form-input">
        <input
          id="college"
          v-model="formRef.college.value"
          class="uk-input"
          name="college"
          type="text"
          placeholder="◯◯大学"
        >
        <error
          v-if="formRef.college.errorMessage"
          :message="formRef.college.errorMessage"
        />
      </div>
    </div>

    <!-- 文理系統 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>文理系統
      </p>
      <div class="form-input">
        <select
          id="literary"
          v-model="formRef.literary.value"
          class="uk-select"
          name="literary"
        >
          <option value="">
            選択してください
          </option>
          <option
            v-for="l in literaryRef"
            :key="l"
            :value="l"
          >
            {{ l }}
          </option>
        </select>
        <error
          v-if="formRef.literary.errorMessage"
          :message="formRef.literary.errorMessage"
        />
      </div>
    </div>

    <!-- 学部 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>学部
      </p>
      <div class="form-input">
        <input
          id="last_name"
          v-model="formRef.department.value"
          class="uk-input"
          name="department"
          type="text"
          placeholder="学部"
        >
        <error
          v-if="formRef.last_name.errorMessage"
          :message="formRef.department.errorMessage"
        />
      </div>
    </div>

    <!-- 学科 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>学科
      </p>
      <div class="form-input">
        <input
          id="last_name"
          v-model="formRef.sub_department.value"
          class="uk-input"
          name="sub_department"
          type="text"
          placeholder="学科"
        >
        <error
          v-if="formRef.last_name.errorMessage"
          :message="formRef.sub_department.errorMessage"
        />
      </div>
    </div>

    <!-- クラブ名 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>クラブ名
      </p>
      <div class="form-input">
        <select
          id="club"
          v-model="formRef.club.value"
          class="uk-select"
          name="club"
        >
          <option value="">
            選択してください
          </option>
          <option
            v-for="c in clubRef"
            :key="c"
            :value="c"
          >
            {{ c }}
          </option>
        </select>
        <error
          v-if="formRef.club.errorMessage"
          :message="formRef.club.errorMessage"
        />
      </div>
    </div>

    <!-- 体育会所属有無 -->
    <div class="wrap-single-form">
      <p class="form-label">
        <span class="attention">※</span>体育会所属有無
      </p>
      <div class="form-input">
        <select
          id="gymnasium"
          v-model="formRef.gymnasium.value"
          class="uk-select"
          name="gymnasium"
        >
          <option value="">
            選択してください
          </option>
          <option
            v-for="g in gymnasiumRef"
            :key="g"
            :value="g"
          >
            {{ g }}
          </option>
        </select>
        <error
          v-if="formRef.gymnasium.errorMessage"
          :message="formRef.gymnasium.errorMessage"
        />
      </div>
    </div>

    <!-- STEP2へ進むボタン -->
    <div class="form-btn-area">
      <button
        class="uk-button uk-button-primary form-btn"
        @click="onClick"
      >
        入力確認画面へ
        <img
          :src="btnImage"
          alt="右矢印"
          class="form-btn-icon"
        >
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, SetupContext, toRefs, PropType } from 'vue'
import Error from './ErrorLabel.vue'
import { validate } from '../func/step1Form'
import { dispGradyear } from '../func/signup'

type Props = {
  form: Ref
  club: Array<string>
  literary: Array<string>
  gymnasium: Array<string>
  gradyear: Array<string>
}

export default defineComponent({
  components: { Error },
  props: {
    form: {
      type: Object as any,
      required: true,
    },
    club: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    literary: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    gymnasium: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    gradyear: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  emits: ['complete'],

  setup(props: Props, context: SetupContext): unknown {
    const { form, club, literary, gymnasium, gradyear } = toRefs(props)
    const btnImage = '/images/right-arrow-icon.png'

    // クリックイベントハンドラ
    const onClick = async (ev: any) => {
      const res = await validate(props.form)
      if (!res) {
        ev.preventDefault()
        document.getElementById('signup-form').scrollIntoView()
      } else {
        context.emit('complete')
      }
    }

    return {
      onClick,
      dispGradyear,
      formRef: form,
      clubRef: club,
      literaryRef: literary,
      gymnasiumRef: gymnasium,
      gradyearRef: gradyear,
      btnImage,
    }
  },
})
</script>

<style scoped>
.step1 {
  width: 1000px;
  margin: 0 auto;
}

.wrap-double-form {
  display: grid;
  grid-template-columns: 5fr 20px 5fr;
  margin: 0 15%;
}

.grid-name-form {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  margin: 10px 0;
}

.name-label {
  background-color: white !important;
}

.wrap-single-form {
  display: grid;
  grid-template-columns: 10rem 1fr;
  align-items: center;
  margin: 0 15%;
}

.form-label {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.form-input {
  margin: 10px 0;
}

/* ボタン */
.form-btn-area {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.form-btn {
  background-color: #e12b46;
  border-radius: 50px;
  width: 20rem;
  height: 3rem;
  font-size: 20px;
  font-size: 17px;
}

.form-btn-icon {
  margin-left: 3px;
  width: 25px;
}

/* uikidのスタイルを上書き */
.uk-input {
  height: 35px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  background-color: #F0F3F7 !important;
}

.uk-select {
  height: 35px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  background-color: #F0F3F7 !important;
}

.form-name-parent-box {
  display: grid;
  grid-template-columns: 10rem 1fr;
  align-items: center;
  margin: 0 15% 15px;
}

.attention {
  color: #e22b46;
  margin-right: 5px;
}

.form-name-child-box {
  display: flex;
}

.name-input {
  margin-right: 5px;
}

/* モバイル用スタイル */
@media (max-width:700px) {
  .form-name-parent-box {
    display: block;
    margin: 10px 12px 0;
  }

  .form-name-child-box {
    padding-top: 8px;
  }

  .wrap-single-form {
    padding-top: 10px;
  }
}

@media (max-width: 1000px) {
  .step1 {
    width: auto;
  }

  .wrap-double-form {
    margin: 0 7%;
  }

  .grid-name-form {
    margin-top: 0;
  }

  .wrap-single-form {
    grid-template-columns: 1fr;
    margin: 0 3%;
  }

  .form-label {
    background-color: white;
    margin: 0;
    padding: 0;
  }

  .form-btn {
    width: 16rem;
  }
}
</style>
